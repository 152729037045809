<template>
  <div>
    <!-- <b-card>
      <div>

        <b-row>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h3>
              <i class="fad fa-search-dollar" /> ค้นหา และ แก้ไขข้อมูลลูกค้า
            </h3>
          </b-col>


          <b-col md="3" />
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="ยูเซอร์ไอดี"
              label-for="customDelimiter"
            >
              <v-select
                v-model="search_userID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="username"
                :options="userslist"
                :reduce="username => username.username"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="1"
            class="p-0"
            style="margin-top: 20px"
          >
            <b-button
              block
              variant="gradient-primary"
              @click="
                $router.push({
                  name: 'Deposit-Withdraw',
                  params: { id: search_userID },
                }),
                changeUserEdit()
              "
            >
              <i class="fad fa-cloud-download" />
              ค้นหา
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-row>

        <b-col
          md="4"
        ><b-form-group
          label="ชื่อ"
          label-for="customDelimiter"
        >
          <b-form-input
            id="floating-label2"
            v-model="userDetails.fname"
            type="text"
            placeholder=""
          /> </b-form-group></b-col>
        <b-col
          md="4"
        ><b-form-group
          label="นามสกุล"
          label-for="customDelimiter"
        >
          <b-form-input
            id="floating-label2"
            v-model="userDetails.sname"
            type="text"
            placeholder=""
          />
        </b-form-group>
        </b-col>
        <b-col
          md="4"
        ><b-form-group
          label="เบอร์โทรศัพท์"
          label-for="customDelimiter"
        >
          <b-form-input
            id="floating-label2"
            v-model="userDetails.tel"
            type="text"
            placeholder=""
          />
        </b-form-group>
        </b-col>
        <b-col
          md="4"
        ><b-form-group
          label="รหัสผ่าน"
          label-for="customDelimiter"
        >
          <b-form-input
            id="floating-label2"
            v-model="userDetails.passwords"
            type="text"
            placeholder=""
          />
        </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="ธนาคาร"
            label-for="customDelimiter"
          >
            <v-select
              v-model="userDetails.bank"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="banklist"
              :reduce="name => name.bankcode"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="4"
        ><b-form-group
          label="เลขที่บัญชี"
          label-for="customDelimiter"
        >
          <b-form-input
            id="floating-label2"
            v-model="userDetails.acc_no"
            type="number"
            placeholder=""
          />
        </b-form-group>
        </b-col>
        <b-col
          md="4"
        ><b-form-group
          label="เบอร์ Truemoney Wallet"
          label-for="customDelimiter"
        >
          <b-form-input
            id="floating-label2"
            v-model="userDetails.trueaccno"
            type="number"
            placeholder=""
          />
        </b-form-group>
        </b-col>
        <b-col
          md="4"
        ><b-form-group
          label="เปอร์เซ็นต์คอมมิชชั่น"
          label-for="customDelimiter"
        >
          <b-form-input
            id="floating-label2"
            v-model="userDetails.discount"
            type="number"
            placeholder="เปอร์เซ็นต์คอมมิชชั่น"
          />
        </b-form-group>
        </b-col>
        <b-col md="4">
          <b-button
            class="mt-2"
            variant="gradient-primary"
            @click="update_member()"
          >
            <i class="fad fa-cloud-download" />
            บันทึกข้อมูล
          </b-button>
        </b-col>
      </b-row>
    </b-card> -->
    <b-overlay
      :show="show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller font-scale="3" animation="cylon" />
          <p id="cancel-label">กรุณารอสักครู่...</p>
        </div>
      </template>
      <div>
        <!-- <div class="row match-height">
          <div class="col">
            <b-card>
              <b-row>
                <b-col>
                  <div class="text-center">
                    <b-avatar
                      src="/logo_line.png"
                      size="100px"
                    />
                    <div class="mt-2">
                      <h3>{{ userData.username }}</h3>
                      <h3>{{ userData.name }} {{ userData.surname }}</h3>
                      <small>Tel : {{ userData.tel }}</small><br>
                      <small>เลขที่บัญชี : {{ userData.acc_no }}</small><br>
                      <small>{{ userData.bank_name }}</small><br>
                      <small>TrueMoney Wallet : {{ userData.trueaccno }}</small>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </div>

          <div class="col">
            <statistic-card-with-area-chart
              v-if="datalist"
              icon="ArrowUpIcon"
              color="success"
              :statistic="`${Commas(datalist.depamount.toFixed(2))} บาท`"
              statistic-title="ยอดฝาก"
              :chart-data="depositSeries"
            />
          </div>
          <div class="col">
            <statistic-card-with-area-chart
              v-if="datalist"
              icon="ArrowDownIcon"
              color="danger"
              :statistic="`${Commas(datalist.withamount.toFixed(2))} บาท`"
              statistic-title="ยอดถอน"
              :chart-data="withdrawSeries"
            />
          </div>
          <div class="col">

            <statistic-card-with-area-chart
              v-if="datalist"
              icon="FileTextIcon"
              color="warning"
              :statistic="`${Commas(datalist.total.toFixed(2))} บาท`"
              statistic-title="ยอดรวม"
            />
          </div>
          <div class="col">
            <statistic-card-with-area-chart
              v-if="datalist"
              icon="FileTextIcon"
              color="info"
              :statistic="`${Commas(datalist.credit.toFixed(2))} บาท`"
              statistic-title="ยอดยังไม่ได้ถอน"
            />
          </div>
        </div> -->

        <!-- <b-col md="3">
            <b-card>
              <b-row>
                <b-col>
                  <div class="text-left">
                    <h3 class="text-center">
                      โปรปัจจุบัน
                    </h3>
                    <div
                      v-if="turn"
                      class="mt-2"
                    >
                      <small
                        style="font-size: 16px"
                      >ชื่อโปรโมชั่น : {{ turn.promotion }}</small><br>
                      <small
                        style="font-size: 16px"
                      >ยอดเทิร์นที่ต้องทำ : {{ turn.userturn }}</small><br>
                      <small
                        style="font-size: 16px"
                      >ยอดเทิร์นปัจจุบัน : {{ turn.turnover }}</small><br>
                      <small
                        style="font-size: 16px"
                      >ต้องทำยอดเทิร์นอีก : {{ turn.difturn }}</small><br>
                    </div>
                    <div
                      class="mt-5"
                      style="display: flex;"
                    >
                      <b-button
                        v-b-modal.modal-success
                        variant="gradient-primary"
                        size="sm"
                        class="m-1"
                        block
                      >
                        <i class="fad fa-history" />
                        ประวัติรับโปร
                      </b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col> -->
        <b-row class="match-height">
          <!-- <b-col md="6">
            <b-card no-body>
              <b-form-group class="mr-1 mb-md-0 p-2">
                <h3>รายการฝาก</h3>
              </b-form-group>

              <b-table
                small
                striped
                hover
                responsive
                class="position-relative items-center"
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
              >
                <template #cell(index)="data">
                  {{ perPage * (currentPage - 1) + (data.index + 1) }}
                </template>
                <template #cell(status)="data">
                  <b-badge
                    :variant="
                      data.item.status === 'success'
                        ? 'success'
                        : data.item.status === 'pending'
                          ? 'warning'
                          : 'danger'
                    "
                    class="badge-glow"
                  >
                    {{
                      data.item.status === 'success'
                        ? 'สำเร็จ'
                        : data.item.status === 'pending'
                          ? 'กำลังดำเนินการ'
                          : 'ไม่สำเร็จ'
                    }}
                  </b-badge>
                </template>

                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      :id="`invoice-row-${data.item.id}-preview-icon`"
                      icon="FileTextIcon"
                      size="16"
                      class="mx-1"
                      @click="
                        $router.push({
                          name: 'dashboard',
                          params: { id: data.item.id },
                        })
                      "
                    />
                    <b-tooltip
                      title="History Deposit & Withdraw"
                      :target="`invoice-row-${data.item.id}-preview-icon`"
                    />

                  </div></template>
              </b-table>

              <b-card-body
                class="d-flex justify-content-between flex-wrap pt-0"
              >

                <b-form-group
                  label="Per Page"
                  label-cols="8"
                  label-align="left"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="text-nowrap mb-md-0 mr-1"
                >
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    inline
                    :options="pageOptions"
                  />
                </b-form-group>


                <div>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </b-card-body>
              <b-modal
                id="modal-1"
                title="Add Class"
                ok-only
                ok-title="Save"
              >
                <b-form-group
                  label="Class"
                  label-for="vi-first-name"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CheckSquareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-first-name"
                      placeholder=""
                    />
                  </b-input-group>
                </b-form-group>
              </b-modal>
            </b-card>
          </b-col> -->
          <!-- <b-col md="6">
            <b-card no-body>
              <b-form-group class="mr-1 mb-md-0 p-2">
                <h3>รายการถอน</h3>
              </b-form-group>

              <b-table
                small
                striped
                hover
                responsive
                class="position-relative items-center"
                :per-page="perPage"
                :current-page="currentPage"
                :items="items2"
                :fields="fields2"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
              >
                <template #cell(index)="data">
                  {{ perPage * (currentPage - 1) + (data.index + 1) }}
                </template>
                <template #cell(status)="data">
                  <b-badge
                    :variant="
                      data.item.status === 'waiting'
                        ? 'info'
                        : data.item.status === 'hold_success'
                          ? 'success'
                          : data.item.status === 'waiting_approve'
                            ? 'secondary'
                            : data.item.status === 'success'
                              ? 'success'
                              : data.item.status === 'processing'
                                ? 'warning'
                                : data.item.status === 'restore'
                                  ? 'dark'
                                  : 'danger'
                    "
                    class="badge-glow"
                  >
                    {{
                      data.item.status === 'waiting'
                        ? 'รอทำรายการ'
                        : data.item.status === 'hold_success'
                          ? 'ยึดเครดิตสำเร็จ'
                          : data.item.status === 'waiting_approve'
                            ? 'รอยืนยัน'
                            : data.item.status === 'success'
                              ? 'สำเร็จ'
                              : data.item.status === 'processing'
                                ? 'กำลังดำเนินการ'
                                : data.item.status === 'restore'
                                  ? 'คืนเงิน'
                                  : 'ไม่สำเร็จ'
                    }}
                  </b-badge>
                </template>

                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      :id="`invoice-row-${data.item.id}-preview-icon`"
                      icon="FileTextIcon"
                      size="16"
                      class="mx-1"
                      @click="
                        $router.push({
                          name: 'dashboard',
                          params: { id: data.item.id },
                        })
                      "
                    />
                    <b-tooltip
                      title="History Deposit &amp; Withdraw"
                      :target="`invoice-row-${data.item.id}-preview-icon`"
                    />

                  </div>
                </template>
              </b-table>

              <b-card-body
                class="d-flex justify-content-between flex-wrap pt-0"
              >

                <b-form-group
                  label="Per Page"
                  label-cols="8"
                  label-align="left"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="text-nowrap mb-md-0 mr-1"
                >
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    inline
                    :options="pageOptions"
                  />
                </b-form-group>


                <div>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </b-card-body>
              <b-modal
                id="modal-1"
                title="Add Class"
                ok-only
                ok-title="Save"
              >
                <b-form-group
                  label="Class"
                  label-for="vi-first-name"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CheckSquareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-first-name"
                      placeholder=""
                    />
                  </b-input-group>
                </b-form-group>
              </b-modal>
            </b-card>
          </b-col> -->
          <!-- รายการเดินบัญชี -->
          <b-col cols="12">
            <b-overlay
              :show="ShowAllBet"
              variant="transparent"
              opacity="0.99"
              blur="5px"
              rounded="sm"
            >
              <template #overlay>
                <div class="text-center">
                  <b-icon-controller font-scale="3" animation="cylon" />
                  <p id="cancel-label">กรุณารอสักครู่...</p>
                </div>
              </template>
              <b-card no-body>
                <b-card-body>
                  <b-form-group class="mr-1 mb-md-0">
                    <h5>
                      <router-link :to="{ name: 'manage-master-win-loss'}" v-if="username_agent">
                        <span class="text-warning">{{ userDatas.agent_name }}</span> 

                        <!-- style="color: blue" -->
                      </router-link>
                      <router-link :to="{ name: 'manage-master-win-loss-detail', params: { id: username_agent.agent_id }  }" v-if="username_agent">
                      <span> / </span>  <span class="text-warning">{{ username_agent.username }}</span>
                        <!-- style="color: blue" -->
                      </router-link>
                      <router-link :to="{ name: 'manage-win-loss' }" v-else>
                      <span class="text-warning">{{ userDatas.agent_name }}</span>

                        <!-- style="color: blue" -->
                      </router-link>
                      
                      <span> / </span><span class="text-warning">{{ $route.params.id }}</span>
                    </h5>
                  </b-form-group>
                  <div class="d-flex justify-content-between flex-wrap">
                    <b-form-group class="mr-1 mb-md-0">
                      <h3>รายการแทงทั้งหมด</h3>
                    </b-form-group>
                    <!-- <b-form-group
                    label="Filter"
                    label-cols-sm="2"
                    label-align-sm="left"
                    label-size="sm"
                    label-for="filterInput"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filterInput"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                      />
                      <b-input-group-append>
                        <b-button
                          :disabled="!filter"
                          @click="filter = ''"
                        >
                          Clear
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group> -->
                  </div>
                </b-card-body>
                <b-table
                  small
                  striped
                  hover
                  responsive
                  class="position-relative items-center"
                  :per-page="perPageHis"
                  :items="itemsGame"
                  :fields="fieldsGame"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                >
                  <template #cell(index)="data">
                    {{ perPageHis * (currentPageHis - 1) + (data.index + 1) }}
                  </template>
                  <template #cell(status)="data">
                    <b-badge
                      :variant="
                        data.item.status === 'confirm' ? 'success' : 'danger'
                      "
                      class="badge-glow"
                    >
                      {{ data.item.status === "confirm" ? "ยืนยัน" : "ยกเลิก" }}
                    </b-badge>
                  </template>
                  <template #cell(status_result)="data">
                    <b-badge
                      :variant="
                        data.item.status_result === 'win'
                          ? 'success'
                          : data.item.status_result === 'waiting'
                          ? 'info'
                          : data.item.status_result === 'processing'
                          ? 'warning'
                          : 'danger'
                      "
                      class="badge-glow"
                    >
                      {{
                        data.item.status_result === "win"
                          ? "ถูกรางวัล"
                          : data.item.status_result === "waiting"
                          ? "รอผล"
                          : data.item.status_result === "processing"
                          ? "กำลังตรวจ"
                          : "ไม่ถูกรางวัล"
                      }}
                    </b-badge>
                  </template>
                  <template #cell(LottoSubHead)="data">
                    {{
                      data.item.LottoSubHead
                        ? NameLottoSubHead(
                            data.item.LottoHead,
                            data.item.LottoSubHead
                          )
                        : "-"
                    }}
                  </template>
                  <!-- Column: Actions -->
                  <template #cell(actions)="data">
                    <div class="text-nowrap">
                      <feather-icon
                        :id="`invoice-row-${data.item.id}-preview-icon`"
                        icon="FileTextIcon"
                        size="16"
                        class="mx-1"
                        @click="
                          $router.push({
                            name: 'dashboard',
                            params: { id: data.item.id },
                          })
                        "
                      />
                      <b-tooltip
                        title="History Deposit & Withdraw"
                        :target="`invoice-row-${data.item.id}-preview-icon`"
                      />
                    </div>
                  </template>
                </b-table>

                <b-card-body
                  class="d-flex justify-content-between flex-wrap pt-0"
                >
                  <!-- page length -->
                  <b-form-group
                    label="Per Page"
                    label-cols="8"
                    label-align="left"
                    label-size="sm"
                    label-for="sortBySelect"
                    class="text-nowrap mb-md-0 mr-1"
                  >
                    <b-form-select
                      id="perPageSelect"
                      v-model="perPageHis"
                      size="sm"
                      inline
                      :options="pageOptions"
                      @change="gethistory()"
                    />
                  </b-form-group>

                  <!-- pagination -->
                  <div>
                    <b-pagination
                      v-model="currentPageHis"
                      :total-rows="totalRowsGame"
                      :per-page="perPageHis"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mb-0"
                      @input="gethistory()"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </b-card-body>
                <b-modal id="modal-1" title="Add Class" ok-only ok-title="Save">
                  <b-form-group label="Class" label-for="vi-first-name">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="CheckSquareIcon" />
                      </b-input-group-prepend>
                      <b-form-input id="vi-first-name" placeholder="" />
                    </b-input-group>
                  </b-form-group>
                </b-modal>
              </b-card>
            </b-overlay>
          </b-col>
          <b-col md="12">
            <b-card no-body>
              <b-form-group class="mr-1 mb-md-0 p-2">
                <h3>รายได้จากหวย</h3>
              </b-form-group>
              <b-table
                small
                  striped
                  hover
                  responsive
                  class="position-relative items-center"
                  :per-page="perPage"
                  :items="items3"
                  :fields="fields3"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter="filter"
                  :filter-included-fields="filterOn"
              >
              <!-- :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn" -->
                <template #cell(index)="data">
                  {{ perPage * (currentPage - 1) + (data.index + 1) }}
                </template>
                <template #cell(reward)="data">
                  {{ data.item.reward ? Commas(data.item.reward.toFixed(2)): '0.00' }}
                </template>
                <template #cell(startbalance)="data">
                  {{ data.item.startbalance ? Commas(data.item.startbalance.toFixed(2)): '0.00' }}
                </template>
                <template #cell(endbalance)="data">
                  {{ data.item.endbalance ? Commas(data.item.endbalance.toFixed(2)): '0.00' }}
                </template>
                <template #cell(status)="data">
                  <b-badge
                    :variant="
                      data.item.status === 'success'
                        ? 'success'
                        : data.item.status === 'pending'
                        ? 'warning'
                        : 'danger'
                    "
                    class="badge-glow"
                  >
                    {{
                      data.item.status === "success"
                        ? "สำเร็จ"
                        : data.item.status === "pending"
                        ? "กำลังดำเนินการ"
                        : "ไม่สำเร็จ"
                    }}
                  </b-badge>
                </template>
                <!-- Column: Actions -->
                <!-- <template #cell(actions)="data">
                  <div class="text-nowrap" />
                </template> -->
              </b-table>

              <b-card-body
                  class="d-flex justify-content-between flex-wrap pt-0"
                >
                  <!-- page length -->
                  <b-form-group
                    label="Per Page"
                    label-cols="8"
                    label-align="left"
                    label-size="sm"
                    label-for="sortBySelect"
                    class="text-nowrap mb-md-0 mr-1"
                  >
                    <b-form-select
                      id="perPageSelect"
                      v-model="perPage"
                      size="sm"
                      inline
                      :options="pageOptionsR"
                      @change="getUsers()"
                    />
                  </b-form-group>

                  <!-- pagination -->
                  <div>
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mb-0"
                      @input="getUsers()"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </b-card-body>
              <!-- <b-modal id="modal-1" title="Add Class" ok-only ok-title="Save">
                <b-form-group label="Class" label-for="vi-first-name">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CheckSquareIcon" />
                    </b-input-group-prepend>
                    <b-form-input id="vi-first-name" placeholder="" />
                  </b-input-group>
                </b-form-group>
              </b-modal> -->
            </b-card>
          </b-col>
          <!-- <b-col md="6">
            <b-card no-body>
              <b-form-group class="mr-1 mb-md-0 p-2">
                <h3>รายได้จากเครือข่าย</h3>
              </b-form-group>
              <b-table
                small
                striped
                hover
                responsive
                class="position-relative items-center"
                :per-page="perPage"
                :current-page="currentPage"
                :items="items4"
                :fields="fields4"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
              >
                <template #cell(index)="data">
                  {{ perPage * (currentPage - 1) + (data.index + 1) }}
                </template>
                <template #cell(status)="data">
                  <b-badge
                    :variant="
                      data.item.status === 'waiting'
                        ? 'info'
                        : data.item.status === 'hold_success'
                          ? 'success'
                          : data.item.status === 'waiting_approve'
                            ? 'secondary'
                            : data.item.status === 'success'
                              ? 'success'
                              : data.item.status === 'processing'
                                ? 'warning'
                                : data.item.status === 'restore'
                                  ? 'dark'
                                  : 'danger'
                    "
                    class="badge-glow"
                  >
                    {{
                      data.item.status === 'waiting'
                        ? 'รอทำรายการ'
                        : data.item.status === 'hold_success'
                          ? 'ยึดเครดิตสำเร็จ'
                          : data.item.status === 'waiting_approve'
                            ? 'รอยืนยัน'
                            : data.item.status === 'success'
                              ? 'สำเร็จ'
                              : data.item.status === 'processing'
                                ? 'กำลังดำเนินการ'
                                : data.item.status === 'restore'
                                  ? 'คืนเงิน'
                                  : 'ไม่สำเร็จ'
                    }}
                  </b-badge>
                </template>

                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      :id="`invoice-row-${data.item.id}-preview-icon`"
                      icon="FileTextIcon"
                      size="16"
                      class="mx-1"
                      @click="
                        $router.push({
                          name: 'dashboard',
                          params: { id: data.item.id },
                        })
                      "
                    />
                    <b-tooltip
                      title="History Deposit &amp; Withdraw"
                      :target="`invoice-row-${data.item.id}-preview-icon`"
                    />
                  </div>
                </template>
              </b-table>

              <b-card-body
                class="d-flex justify-content-between flex-wrap pt-0"
              >

                <b-form-group
                  label="Per Page"
                  label-cols="8"
                  label-align="left"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="text-nowrap mb-md-0 mr-1"
                >
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    inline
                    :options="pageOptions"
                  />
                </b-form-group>


                <div>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </b-card-body>
              <b-modal
                id="modal-1"
                title="Add Class"
                ok-only
                ok-title="Save"
              >
                <b-form-group
                  label="Class"
                  label-for="vi-first-name"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CheckSquareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-first-name"
                      placeholder=""
                    />
                  </b-input-group>
                </b-form-group>
              </b-modal>
            </b-card>
          </b-col> -->
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  // BInputGroupAppend,
  BRow,
  BCol,
  BInputGroupPrepend,
  BModal,
  BCard,
  BTable,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BButton,
  BCardBody,
  VBToggle,
  BOverlay,
  BIconController,
  BBadge,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import { kFormatter } from "@core/utils/filter";
import moment from "moment-timezone";
// import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    // BInputGroupAppend,
    BRow,
    BCol,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    StatisticCardWithAreaChart,
    // VueApexCharts,
    BOverlay,
    BIconController,
    vSelect,
    BBadge,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      ShowAllBet: false,
      show: false,
      perPage: 10,
      pageOptions: [10, 15, 20, 50, 100],
      pageOptionsR: [10, 15, 20, 50, 100],
      totalRows: 1,
      totalRowspro: 1,
      totalRows2: 1,
      totalRowsGame: 1,
      currentPage: 1,
      perPageHis: 10,
      currentPageHis: 1,
      userData: [],
      withamount: null,
      depamount: null,
      total: null,
      totalpro: null,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      usersList: [],
      banklist: [],
      fields: [
        { key: "index", label: "ลำดับที่" },
        { key: "username", label: "username" },
        {
          key: "amount",
          label: "amount",
          formatter: (value) => this.Commas(value),
        },
        {
          key: "bonus",
          label: "bonus",
          formatter: (value) =>
            value && value !== 0 ? this.Commas(value) : "-",
        },
        {
          key: "balance",
          label: "balance",
          formatter: (value) => this.Commas(value),
        },
        { key: "status", label: "status" },
        {
          key: "created_at",
          label: "เวลา",
          formatter: (value) =>
            moment(value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm:ss"),
        },
        // { key: 'actions', label: 'Actions' },
      ],
      itemsGame: [],
      /* eslint-disable global-require */
      items: [],
      fields2: [
        { key: "index", label: "ลำดับที่" },
        { key: "username", label: "username" },
        {
          key: "amount",
          label: "amount",
          formatter: (value) => this.Commas(value),
        },
        {
          key: "balance",
          label: "balance",
          formatter: (value) => this.Commas(value),
        },
        { key: "status", label: "status" },
        {
          key: "created_at",
          label: "เวลา",
          formatter: (value) =>
            moment(value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm:ss"),
        },
        // { key: 'actions', label: 'Actions' },
      ],
      fields3: [
        { key: "index", label: "ลำดับที่" },
        { key: "username", label: "username" },
        { key: "LottoHeadName", label: "หวย" },
        {
          key: "reward",
          label: "reward",
          // formatter: (value) => value ? this.Commas(value.toFixed(2)): 0,
        },
        {
          key: "startbalance",
          label: "startbalance",
          // formatter: (value) => value ? this.Commas(value.toFixed(2)): 0,
        },
        {
          key: "endbalance",
          label: "endbalance",
          // formatter: (value) => value ? this.Commas(value.toFixed(2)): 0,
        },
        // { key: 'status', label: 'status' },
        {
          key: "created_at",
          label: "เวลา",
          formatter: (value) =>
            moment(value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm:ss"),
        },
        // { key: 'actions', label: 'Actions' },
      ],
      fields4: [
        { key: "index", label: "ลำดับที่" },
        { key: "username", label: "username" },
        { key: "ref_username", label: "ได้เงินจาก" },
        {
          key: "reward",
          label: "reward",
          formatter: (value) => this.Commas(value.toFixed(2)),
        },
        {
          key: "startbalance",
          label: "startbalance",
          formatter: (value) => this.Commas(value.toFixed(2)),
        },
        {
          key: "endbalance",
          label: "endbalance",
          formatter: (value) => this.Commas(value.toFixed(2)),
        },
        // { key: 'status', label: 'status' },
        {
          key: "created_at",
          label: "เวลา",
          formatter: (value) =>
            moment(value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm:ss"),
        },
        // { key: 'actions', label: 'Actions' },
      ],
      seriesDep: [95],
      seriesWith: [75],
      seriesTotal: [65],
      // data: {},
      /* eslint-disable global-require */
      fieldsGame: [
        { key: "index", label: "ลำดับที่" },
        { key: "list_id", label: "โพยที่" },
        { key: "RoundData.CloseDateName", label: "งวดที่" },
        {
          key: "status",
          label: "สถานะแทง",
        }, // confirm , cancel
        {
          key: "status_result",
          label: "สถานะออกหวย",
        },
        { key: "username", label: "ยูสเซอร์เนม" },
        {
          key: "LottoHeadName",
          label: "หวย",
          // formatter: value => this.NameLottoHead(value) || '-',
        },
        // { key: 'LottoSubHead', label: 'ประเภทหวย' },
        { key: "bet", label: "เลข" },
        { key: "name_type", label: "ประเภท" },
        {
          key: "amount",
          label: "ยอด",
          formatter: (value) => this.Commas(value),
        },
        { key: "win_rate", label: "จ่าย" },
        // {
        //   key: 'startbalance',
        //   label: 'ราคาก่อนแทง',
        //   formatter: value => this.Commas(value),
        // },
        {
          key: "endbalance",
          label: "เครดิตหลังแทง",
          formatter: (value) => this.Commas(value),
        },
        {
          key: "created_at",
          label: "เวลาแทง",
          formatter: (value) =>
            moment(value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm:ss"),
        },
        // { key: 'actions', label: 'Actions' },
      ],
      items2: [],
      items3: [],
      items4: [],
      search_userID: null,
      userDetails: [],
      userslist: [],
      depositSeries: [
        {
          name: "deposit",
          data: [],
        },
      ],
      withdrawSeries: [
        {
          name: "withdraw",
          data: [],
        },
      ],
      turn: "",
      turnhis: "",
      LottoList: JSON.parse(localStorage.getItem("LottoList")),
      userDatas: JSON.parse(localStorage.getItem("userData")),
      
    };
  },
  computed: {
    username_agent() {
      return JSON.parse(localStorage.getItem('username_agent'))
    },
    SearchFilter() {
      return JSON.parse(localStorage.getItem('SearchFilter'))
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  created() {
    this.$http.get("/analytics/data").then((response) => {
      this.data = response.data;
    });
  },
  mounted() {
    this.getUsers();
    // this.Getuserdetail()
    // this.Get_bank()
    // this.Get_userlists()
    this.gethistory();
  },
  methods: {
    kFormatter,
    NameLottoHead(Val) {
      const Index = this.LottoList.findIndex(({ value }) => value === Val);
      return this.LottoList[Index].text;
    },
    NameLottoSubHead(Head, Sub) {
      if (Head === Sub) {
        return "-";
      }
      const IndexHead = this.LottoList.findIndex(({ value }) => value === Head);
      const IndexSub = this.LottoList[IndexHead].SubList.findIndex(
        ({ value }) => value === Sub
      );
      return this.LottoList[IndexHead].SubList[IndexSub].text;
    },
    gethistory() {
      // const index = 0
      this.ShowAllBet = true;
      const params = {
        page: this.currentPageHis,
        perpage: this.perPageHis,
      };
      if (this.SearchFilter) { 
        params.selected = this.SearchFilter.selected
        if (this.SearchFilter.days) {
          params.days = this.SearchFilter.days
        } else if (this.SearchFilter.start && this.SearchFilter.end) {
          params.start = this.SearchFilter.start
          params.end = this.SearchFilter.end
        }
      }
      this.$http
        .get(`/history/play/${this.$route.params.id}`, { params })
        .then((response) => {
          // this.listdata = response.data.Data
          // this.listdata.forEach(items => {
          //   this.listgames(items.gamecode, index)
          //   index += 1
          //   // console.log(items.gamecode)
          // })
          console.log(response.data);
          this.onFilteredHis(response.data);
          this.ShowAllBet = false;
        })
        .catch((error) => {
          this.ShowAllBet = false;
          console.log(error);
        });
    },
    onFilteredHis(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      console.log(filteredItems);
      this.itemsGame = filteredItems.Data;
      this.totalRowsGame = filteredItems.total;
    },
    changeUserEdit() {
      this.getUsers();
      this.Getuserdetail();
      this.Get_bank();
      this.Get_userlists();
      this.gethistory();
      this.search_userID = null;
    },
    Get_bank() {
      this.$http
        .get("bank/list")
        .then((response) => {
          this.banklist = response.data;
        })
        .catch((error) => console.log(error));
    },
    Get_userlists() {
      this.$http
        .get("users/list")
        .then((response) => {
          this.userslist = response.data;
        })
        .catch((error) => console.log(error));
    },
    update_member() {
      const params = {
        fname: this.userDetails.fname,
        password: this.userDetails.passwords,
        sname: this.userDetails.sname,
        bank: this.userDetails.bank,
        tel: this.userDetails.tel,
        acc_no: this.userDetails.acc_no,
        trueaccno: this.userDetails.trueaccno,
        discount: this.userDetails.discount,
      };
      // console.log(params)
      this.$http
        .post(`users/update_namepass/${this.userDetails.id}`, params)
        .then((response) => {
          console.log(response);
          this.show = false;
          this.Success("แก้ไขข้อมูลลูกค้าเรียบร้อย");
          this.Getuserdetail();
        })
        .catch((error) => console.log(error));
    },
    Getuserdetail() {
      this.$http
        .get(`users/showByusername/${this.$route.params.id}`)
        .then((response) => {
          this.userDetails = response.data;
          // console.log(this.userDetails)
        })
        .catch((error) => console.log(error));
    },
    getUsers() {
      this.show = true;
      const params = {
        perPage: this.perPage,
        Page: this.currentPage,
        id: this.$route.params.id
      }
      this.$http
        .get(`/history/rewardlist/`, { params})
        .then((response) => {
          this.items3 = response.data.DataReward;
          this.totalRows = response.data.total
          this.show = false;
          // this.depositSeries[0].data = response.data.ddata
          // this.withdrawSeries[0].data = response.data.wdata
          // this.datalist = response.data
          // this.onFiltered(response.data)
        })
        .catch((error) => console.log(error));
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // console.log(filteredItems)
      this.items = filteredItems.DataDep;
      this.items2 = filteredItems.DataWith;
      this.items3 = filteredItems.DataReward;
      this.items4 = filteredItems.DataRewardInvite;
      this.totalRows = filteredItems.DataDep.length;
      this.totalRows2 = filteredItems.DataWith.length;
      this.userData = filteredItems.userData;
      this.withamount = filteredItems.withamount;
      this.depamount = filteredItems.depamount;
      this.total = filteredItems.total;
      this.seriesDep = [filteredItems.depamount];
      this.seriesWith = [filteredItems.withamount];
      this.seriesTotal = [filteredItems.total];
      // console.log(this.userData)
    },
    Commas(x) {
      return Number(x)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    Success(mes) {
      this.$swal({
        icon: "success",
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    SwalError(mes) {
      this.$swal({
        icon: "error",
        title: '<h3 style="color: #000">Error!</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.form-item-section {
  background-color: $product-details-bg;
}
</style>
